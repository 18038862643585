import { RouteRecordRaw, RouteLocation } from 'vue-router'
import SectionsChildRoutes from '@/router/sections'
import exportsChildRoutes from '@/router/exports'
import projectParamAssessmentRoutes from '@/router/project-param-assessments'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'sections',
    name: 'control-panel.sections',
    component: () => import('@/views/control-panel/projects/project/Sections.vue'),
    meta: { title: 'Alle deelprojecten' },
    redirect: (to: RouteLocation) => ({ name: 'map.sections', params: to.params }),
    children: SectionsChildRoutes
  },
  {
    path: 'exports',
    name: 'control-panel.exports',
    component: () => import('@/views/control-panel/projects/project/Exports.vue'),
    meta: { title: 'Alle exports' },
    redirect: (to: RouteLocation) => ({ name: 'control-panel.exports.list', params: to.params }),
    children: exportsChildRoutes
  },
  {
    path: 'edit',
    name: 'control-panel.project.edit',
    component: () => import('@/views/control-panel/projects/project/Edit.vue'),
  },
  {
    path: 'param-assessments',
    name: 'project.param-assessments',
    component: () => import('@/views/control-panel/projects/project/ParamAssessments.vue'),
    redirect: () => ({ name: 'project.param-assessments.list' }),
    children: projectParamAssessmentRoutes
  },
  {
    path: 'concentration-table',
    name: 'project.concentration-table',
    redirect: (to) => ({ name: 'project.concentration-table.period', params: to.params }),
    component: () => import('@/views/control-panel/projects/project/ConcentrationTable.vue'),
    children: [
      {
        path: 'period',
        name: 'project.concentration-table.period',
        component: () => import('@/views/control-panel/projects/project/concentration-table/Period.vue'),
      },
      {
        path: 'point',
        name: 'project.concentration-table.point',
        component: () => import('@/views/control-panel/projects/project/concentration-table/Point.vue'),
      }
    ]
  },
  {
    path: 'chart',
    name: 'project.chart',
    component: () => import('@/views/control-panel/projects/project/Chart.vue')
  }
]

export default routes
